import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { calculate } from "./LogModalOnTime";
import WifiSessions from "./WifiSessions";

export default function OnTimeRow({ deviceId, switchId }) {
  let [onTime, setOntime] = useState({ ontime: 0, sessions: [], commands: [] });
  const todayLogOntime = async () => {
    const startDate = new Date(new Date().setDate(new Date().getDate() - 1));
    startDate.setHours(10, 0, 0, 0);
    const today = new Date(new Date().setDate(new Date().getDate()));
    today.setHours(10, 0, 0, 0);
    const fetchData = async () => {
      try {
        const res = await axios.post(
          "https://c6qofus8ee.execute-api.ap-south-1.amazonaws.com/default/legacy/dateAnalytics",
          {
            deviceId: `${deviceId}`,
            startDate: startDate,
            endDate: today,
          }
        );

        if (res.data.company === "success") {
          const findd = res.data.final.find((e) => e.switchId === switchId);
          if (findd) {
            setOntime(findd);
          }
          return { ...findd };
        } else {
          setOntime({ ontime: 0, sessions: [], commands: [] });
          return { ontime: 0, sessions: [], commands: [] };
        }
      } catch (err) {
        //console.log(err);
        setOntime({ ontime: 0, sessions: [], commands: [] });
        return { ontime: 0, sessions: [], commands: [] };
      }
    };
    fetchData();
  };
  useEffect(() => {
    todayLogOntime();
  }, [deviceId]);
  return (
    <>
      <td className="tableData">{calculate(onTime?.ontime)}</td>
      <WifiSessions
        wifiTime={onTime?.commands !== undefined ? onTime?.commands : []}
      />
    </>
  );
}
