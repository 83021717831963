import axios from "axios"
import { serverUrl } from "../../../ApiUri"
import { logs } from "./ActionTypes"

export const todayLogSave=(data)=>{
    return{
        type:logs.TODAY_GET_LOGS,
        payload:data
    }
}

export const emptyTodayLogs=(data)=>{
    return{
        type:logs.EMPTY_TODAY_LOGS,
        payload:data
    }
}


export const todayLogs =(dispatch,deviceId)=>{
    axios.post(`https://analytics.alistetechnologies.com:443/analytic/TodayLogsByDateDevice`,{
        "deviceId" : deviceId,
        "roomId" : "DFS"
    }).then(function (res) {
        if(res.data.final.length===0){
            dispatch(todayLogSave({success:false,message:"No Logs Found",final:[]}))
        }else if(res.data.final.length!==0){
            dispatch(todayLogSave({success:true,message:"Logs Found",final:res.data.final}))
        }
         
    }).catch(function (err) {
        //console.log(err);
        dispatch(todayLogSave({success:false,message:"No Logs Found",final:[]}))
    })
}

