import moment from 'moment/moment'
import React from 'react'
import { useState } from 'react'
import { Button, Modal, Stack, Table } from 'react-bootstrap'

function WifiSessions({ wifiTime }) {
    let [open, setOpen] = useState(false)
    return (
        <>
        <td  className='tableData'>
            <Stack>
            <Button variant="outline-secondary" size='sm' style={{
                display: "flex",
                alignItems: "center",
                justifyContent:'center',
                maxWidth:'100px'
            }}
            onClick={()=>setOpen(true)}
            >Detail</Button>
            </Stack>
           </td>
            <Modal
                show={open}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >

                <Modal.Body>
                    <div style={{ textAlign: "right" }}>
                        <span
                            onClick={() => {
                                setOpen(false)
                            }}
                            style={{ fontSize: "18px", cursor: "pointer" }}
                        >X</span>
                    </div>
                    {
                        (wifiTime.length !== 0) ?
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Switch State</th>
                                        <th>Connection Time</th>
                                        <th>Command By</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        wifiTime.filter((ee,ii)=>((ii > 0 && !['disconnect',"state update"].includes(ee.medium)) || ii === 0)).map((e, i) => {
                                          
                                                return (
                                                    <tr key={i}>
                                                        <td>{i+1}</td>
                                                        <td>{e?.state === 0 ? "Off" : "On"}</td>
                                                        <td>{moment(e?.timestamp).format('DD-MMM-YYYY, LT')}</td>
                                                        <td>{e?.medium}</td>
                                                    </tr>
                                                )

                                        })
                                    }
                                </tbody>
                            </Table> : <h5>{"Commands Not Available"}</h5>
                    }


                </Modal.Body>

            </Modal>
        </>
    )
}

export default WifiSessions