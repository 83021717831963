import axios from "axios";
import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Form, Modal, Stack, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import WifiSessions from "./WifiSessions";
import { CSVLink } from "react-csv";
export const calculate = (date) => {
  let milliseconds = date;
  if (milliseconds <= 0) {
    milliseconds = 0;
  }
  const seconds = Math.floor((milliseconds / 1000) % 60);

  const minutes = Math.floor((milliseconds / 1000 / 60) % 60);

  const hours = Math.floor((milliseconds / 1000 / 60 / 60) % 24);

  const formattedTime = [
    hours.toString().padStart(2, "0"),
    minutes.toString().padStart(2, "0"),
    seconds.toString().padStart(2, "0"),
  ];

  return `${hours.toString().padStart(2, "0")}h ${minutes
    .toString()
    .padStart(2, "0")}m `;
};
export default function LogModalOnTime(props) {
  let [logs, setLogs] = useState([]);
  let [message, setMessage] = useState("");
  const { logswitch, show } = props;
  console.log(logswitch,"gfff");
  useEffect(() => {
    if (show === false) return;
    setMessage("");
    setLogs([]);

    const startDate = new Date(new Date().setDate(new Date().getDate() - 7));
    startDate.setHours(10, 0, 0, 0);

    const dateArray = [];
    for (let i = 0; i <= 7; i++) {
      const date = new Date(startDate);
      date.setDate(startDate.getDate() + i);
      dateArray.push(new Date(date.setHours(10, 0, 0, 0)));
    }

    const fetchData = async (start, end) => {
      try {
        const res = await axios.post(
          "https://c6qofus8ee.execute-api.ap-south-1.amazonaws.com/default/legacy/dateAnalytics",
          {
            deviceId: `${logswitch.deviceId}`,
            startDate: start,
            endDate: end,
          }
        );

        if (res.data.company === "success") {
          const findd = res.data.final.find(
            (e) => e.switchId === logswitch.switchId
          );
          return { date: start, ...findd };
        } else {
          setMessage("Data Not Found");
          return { date: start, ontime: 0, sessions: [], commands: [] };
        }
      } catch (err) {
        //console.log(err);
        return { date: start, ontime: 0, sessions: [], commands: [] };
      }
    };

    const fetchAllData = async () => {
      const promises = [];
      for (let j = 0; j < dateArray.length - 1; j++) {
        promises.push(fetchData(dateArray[j], dateArray[j + 1]));
      }
      const dataList = await Promise.all(promises);
      setLogs(dataList);
    };

    fetchAllData();
  }, [logswitch]);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div style={{ textAlign: "right" }}>
          <span
            onClick={() => {
              props.onHide();
              setLogs([]);
              setMessage("");
            }}
            style={{ fontSize: "18px", cursor: "pointer" }}
          >
            X
          </span>
        </div>
        {logs.length !== 0 ? (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Date</th>
                <th>onTime</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {logs &&
                logs.map((date, i) => {
                  //console.log(date, "ffff");
                  return (
                    <tr key={date.date}>
                      <td>{i + 1}</td>
                      <td>{moment.utc(date.date).format("DD-MMM-YYYY")}</td>
                      <td>
                        {calculate(
                          date?.ontime !== undefined ? date.ontime : 0
                        )}
                      </td>
                      <td>
                        <Stack gap={2} direction="horizontal">
                          <WifiSessions
                            wifiTime={
                              date?.commands !== undefined ? date?.commands : []
                            }
                          />
                         
                          {/* {date.commands !== undefined &&
                            date.commands.length > 0 && (
                              <CSVLink

                                style={{
                                  textDecoration:'none'
                                }}
                                data={
                                  date?.commands !== undefined
                                    ? date?.commands
                                        .filter(
                                          (ee, ii) =>
                                            (ii > 0 &&
                                              ![
                                                "disconnect",
                                                "state update",
                                              ].includes(ee.medium)) ||
                                            ii === 0
                                        )
                                        .map((e) => {
                                          return {
                                            "Room Name":logswitch.roomName,
                                            "Switch Name":logswitch.switchName,
                                            "Switch State":
                                              e?.state === 0 ? "Off" : "On",
                                            "Time": moment(
                                              e?.timestamp
                                            ).format("DD-MMM-YYYY, LT"),
                                           
                                          };
                                        })
                                    : []
                                }
                                filename={`${moment
                                  .utc(date.date)
                                  .format("DD-MMM-YYYY")} - ${
                                  logswitch.switchName
                                }.csv`}
                              >
                                <Button
                                  variant="outline-secondary"
                                  size="sm"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    maxWidth: "100px",
                                    textDecoration:'none'
                                  }}
                                >
                                  Download
                                </Button>
                              </CSVLink>
                            )} */}
                        </Stack>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        ) : (
          <h5>{"Loading..."}</h5>
        )}
      </Modal.Body>
    </Modal>
  );
}
